



























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AssetGrid extends Vue {

  @Prop() private heading!: string;
  @Prop() private service!: string;

  private assetsUrl = 'https://images.warp.world/site/brand-assets';
  private assets: any = {
    WW: {
      service: 'WarpWorld',
      assets: [
        {
          type: 'logo',
          variants: [ 'default', 'white'],
          extensions: ['svg', 'png'],
        },
        {
          type: 'lettermark',
          variants: [ 'default', 'white'],
          extensions: ['svg', 'png'],
        },
      ],
    },
    CC: {
      service: 'CrowdControl',
      assets: [
        {
          type: 'logo',
          variants: [ 'default', 'white'],
          extensions: ['svg', 'png'],
        },
        {
          type: 'lettermark',
          variants: [ 'default', 'white'],
          extensions: ['svg', 'png'],
        },
        {
          type: 'wrench',
          variants: [ 'default', 'white'],
          extensions: ['svg', 'png'],
        },
        {
          type: 'wrench-simple',
          variants: [ 'default', 'white'],
          extensions: ['svg', 'png'],
        },
      ],
    },
  };

  private flattenenedAssetsArray(service: string) {
    const serviceName = this.assets[service].service;
    const serviceAssets: any[] = this.assets[service].assets;

    const flattenedAssets = serviceAssets.reduce((assetArray, currentAsset) => {
      currentAsset.variants.forEach((variant: string) => {
        let assetObj: any = {};
        assetObj.variant = variant;
        currentAsset.extensions.forEach((extension: string) => {
          // tslint:disable-next-line
          assetObj[extension] = `${this.assetsUrl}/${serviceName}-${currentAsset.type}${variant === 'default' ? '' : `-${variant}`}.${extension}`;
        });
        assetArray.push(assetObj);
        assetObj = null;
      });

      return assetArray;
    }, []);

    return flattenedAssets;
  }

  private hideBrokenImages(event: Event) {
    // @ts-ignore
    (event.target as HTMLElement).parentElement.style.display = 'none';
  }

  private cacheBust() {
    return Math.floor(100000 + Math.random() * 900000);
  }
}
