





















































































import { Component, Vue } from 'vue-property-decorator';
import HeroIntro from '@/components/HeroIntro.vue';
import AssetGrid from '@/components/AssetGrid.vue';
import EventsList from '@/components/EventsList.vue';
import { getStats } from '@/services/crowd-control';

@Component({
  components: {
    HeroIntro,
    AssetGrid,
    EventsList,
  },
})
export default class Press extends Vue {
  private stats: any = {};

  private services = [
    { heading: 'Warp World', service: 'WW'},
    { heading: 'Crowd Control', service: 'CC'},
  ];

  private async mounted() {
    this.stats = await getStats();
  }

}
